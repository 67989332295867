@import "@sass/global.scss";

.main {
  position: relative;
  background: #f7f7f7;
  font-family: "normal";
  min-height: 100vh;
  overflow-x: hidden;
}

// .loading {
//   display: none;
// }
.banner {
  width: 100%;
  background: #fff;
  position: relative;
  padding-top: 46.55%;
  .player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video {
      // width: 100%;
      // height: auto;
      // position: relative;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -50%);
      object-fit: fill;
    }
  }

  .playerModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    .playerTitleFa {
      width: 1200px;
      margin: 0 auto;
      margin-top: 18%;
      text-align: center;
    }

    .playerTitle {
      font-size: 44px;
      color: #fff;
    }
    .playerSubTitle {
      margin-top: 20px;
      font-size: 18px;
      color: #fff;
    }
  }
}
// 关于我们
.about {
  width: 100%;
  background-color: #fff;
  .aboutWrap {
    width: 100%;
    max-width: 1200px;
    padding: 100px 0;
    margin: 0 auto;
    align-items: center;
    .left {
      position: relative;
      width: 40%;
      justify-content: flex-end;
      .imgWrap {
        width: 100%;
        justify-content: flex-end;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .logoAndTitle {
        position: absolute;
        top: -20px;
        left: 0;
        .logoTitleWrap {
          flex-direction: column;
          .title {
            font-size: 24px;
            font-weight: 600;
            color: $mainColor;
            margin-top: 20px;
          }
        }
      }
    }
    .right {
      margin-left: 100px;
      flex: 1;
      .rightContent {
        width: 100%;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: #313131;
          margin-bottom: 20px;
        }
        .content {
          font-size: 14px;
          color: #707070;
          margin-bottom: 30px;
        }
        .more {
          color: #313131;
          margin-top: 20px;
          text-align: left;
          // border: 1px solid #c8cdd3;
          .moreText {
            padding: 5px 20px;
            border: 1px solid #c8cdd3;
            font-size: 16px;
            border-radius: 20px;
          }
        }
      }
    }
  }
}
// 我们的产品
.ourProducts {
  width: 100%;
  height: 400px;
  background-color: #f4f5f9;
  position: relative;
  .ourProductsHeader {
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    margin: 60px auto;
    .topContent {
      justify-content: space-between;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: $mainColor;
      }
      .content {
        display: none;
      }
      .categoryList {
        .activeItem {
          border-bottom: 2px solid $mainColor;
        }
        .item {
          color: $mainColor;
          font-size: 14px;
          padding: 5px 0;
          margin: 0 20px;
          &:hover {
            border-bottom: 2px solid $mainColor;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .content {
      font-size: 16px;
      color: $mainColor;
      margin-top: 10px;
    }
  }
}

.productsContainer {
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  top: 170px;
  height: 600px;
}
.productsListWrap {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  .productsList {
    position: relative;
    width: 100%;
    margin: 30px auto;
    .item {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      .img {
        position: relative;
        width: 100%;
        height: 480px;
        top: 50px;
        background-color: #000;

        opacity: 0.7;
        // object-fit: cover;
        // border: 1px solid yellow;
      }
    }
    :global {
      .slick-dots li.slick-active button:before {
        color: transparent !important;
      }
      .slick-dots li button:before {
        color: transparent !important;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "" !important;
        text-align: center;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
      }
      .slick-active {
        img {
          height: 540px !important;
          top: 0 !important;
          opacity: 1 !important;
        }
      }
      .slick-prev,
      .slick-next {
        width: 45px !important;
        height: 60px !important;
        background: #00000090;
        border-radius: 0% !important;
        &:focus,
        &:hover {
          background: #00000090;
        }
      }
      .slick-prev {
        left: -80px;
        &:before {
          display: block;
          text-align: center;
          font-size: 30px;
          content: "<";
          line-height: 0.5;
          color: #fff;
        }
      }
      .slick-next {
        right: -80px;
        &:before {
          display: block;
          text-align: center;
          font-size: 30px;
          color: #fff;
          content: ">";
          line-height: 0.5;
        }
      }
    }
  }
}
@keyframes living {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 0; /*圆形放大的同时，透明度逐渐减小为0*/
  }
  100% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}
// 项目管理
.projectManage {
  width: 100vw;
  background-color: #fff;
  padding-top: 500px;
  .projectContent {
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    .headerWrap {
      flex-direction: column;
      .mainTitle {
        font-size: 24px;
        color: $mainColor;
      }
      .subTitle {
        margin-top: 15px;
        font-size: 14px;
        color: $mainColor;
      }
    }
    .projectList {
      align-items: center;
      margin: 100px 0;
      margin-bottom: 40px;
      .item {
        width: 25%;
        flex-direction: column;
        justify-content: flex-start;
        .line {
          flex: 1;
          border: 1px dashed#c8cdd3;
          width: 100%;
        }
        .topWrap {
          align-items: center;
          .imgWrap {
            width: 126px;
            height: 126px;
            position: relative;
            img {
              width: 100%;
              border-radius: 50%;
              box-shadow: 0 0 5px 5px #e8e8e8;
              border-radius: 50%;
              z-index: 2;
            }
            // &:hover {
            //   transform: scale(1.1);
            // }
            .circle {
              position: absolute;
              width: 126px;
              height: 126px;
              left: 0;
              bottom: 0;
              // border: 1px solid #fe0000;
              border: 1px solid #2b3c7a;
              // background: #fe0000;
              border-radius: 50%;
              -webkit-animation: living 5s linear infinite;
              z-index: 1;
              &:nth-child(2) {
                -webkit-animation-delay: 1s; /*第二个span动画延迟1.5秒*/
              }
              &:nth-child(3) {
                -webkit-animation-delay: 2s; /*第二个span动画延迟1.5秒*/
              }
            }
          }
        }
        .bottomWrap {
          flex-direction: column;
          align-items: center;
          margin-top: 30px;

          min-height: 350px;
          .title {
            font-size: 24px;
            color: #6a6a6a;
            margin-bottom: 20px;
          }
          .content {
            font-size: 14px;
            color: #a0a0a0;
            line-height: 1.5;
            width: 240px;
            text-align: center;
            p {
              margin-bottom: 0;
            }
          }
        }
        &:nth-child(1) {
          .line {
            &:nth-child(1) {
              opacity: 0;
            }
          }
        }
        &:nth-child(4) {
          .line {
            &:last-child {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
// 公司分布
.companyLocation {
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #2b3c7a, #2c4191);
  .locationContent {
    flex-direction: column;
    align-items: center;
    width: 100%;
    .header {
      flex: 1;
      margin-top: 50px;
      flex-direction: column;
      align-items: center;
      .imgWrap {
        width: 308px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .title {
        font-size: 24px;
        color: #fff;
        margin-top: 20px;
      }
    }
    .bottom {
      max-width: 1200px;
      margin: 35px auto;
      width: 100%;
      position: relative;
      background: url("../../images/home/map.png") no-repeat;
      background-size: cover;
      background-position: center;
      height: 600px;
      .introduceWrap {
        position: absolute;
        top: 50%;
        right: 23%;
        .introduceContent {
          width: 366px;
          font-size: 16px;
          color: #fff;
        }
      }
      .item {
        position: absolute;
        width: 100px;
        height: 100px;
        &:nth-child(1) {
          left: 38%;
          top: 41%;
        }
        &:nth-child(2) {
          left: 32%;
          top: 48%;
        }
        &:nth-child(3) {
          left: 27%;
          bottom: 22%;
        }
        &:nth-child(4) {
          left: 6%;
          top: 28%;
        }
        &:nth-child(5) {
          left: 6.5%;
          top: 30%;
          .location {
            left: auto;
            top: auto;
            right: -66%;
            bottom: 40%;
          }
        }
        &:nth-child(6) {
          left: 6%;
          top: 32%;
          .location {
            left: auto;
            top: auto;
            right: -66%;
          }
        }
        &:nth-child(7) {
          left: 1%;
          top: 36%;
          .location {
            left: auto;
            top: auto;
          }
        }
        &:nth-child(8) {
          left: 3%;
          top: 35%;
        }
        &:nth-child(9) {
          left: 5%;
          top: 37%;
          .location {
            left: auto;
            top: auto;
            right: -45%;
            bottom: -30%;
          }
        }
        .location {
          position: absolute;
          top: -36%;
          left: -5%;
          width: 100px;
          height: 100px;
          span {
            background: rgba($mainColor, 0.9);
            color: #fff;
            padding: 3px 10px;
            font-size: 14px;
          }
        }
        .items {
          position: relative;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @keyframes wateranimate {
            0% {
              transform: scale(1);
              opacity: 0.7;
            }
            100% {
              transform: scale(4);
              opacity: 0;
            }
          }
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #cc0000;
          }
          .circle {
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #fff;
          }
          .circle1 {
            animation: wateranimate 4s 3s ease-out infinite;
          }
          .circle2 {
            animation: wateranimate 4s 2s ease-out infinite;
          }
          .circle3 {
            animation: wateranimate 4s 1s ease-out infinite;
          }
        }
      }
    }
  }
}
// 团队
.teamWrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  padding: 50px 0;
  .mainTitle {
    font-size: 24px;
    color: $mainColor;
  }
  .teamContent {
    width: 100%;
    margin: 30px 0;

    .left {
      flex-direction: column;
      align-items: center;
      :global {
        .slick-prev {
          left: 15px !important;
          z-index: 6;
        }
        .slick-next {
          right: 30px !important;
          z-index: 6;
        }
      }
      .leftContent {
        width: 892px;
        .imgWrap {
          height: 540px;
          max-width: 892px;
          width: 100%;
          transition: all 2s ease-in-out;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .line {
            width: 100%;
            opacity: 1;
            animation: all 2s linear;
          }
        }
      }
      .leftEnContent {
        .imgWrap {
          height: 616px !important;
        }
      }
      .leftDeContent {
        .imgWrap {
          height: 640px !important;
        }
      }
    }
    .right {
      margin-left: 30px;
      flex: 1;
      flex-direction: column;
      align-content: center;
      border-bottom: 2px solid $red;
      .imgWrap {
        width: 280px;
        height: 252px;
        img {
          width: 100%;
        }
      }
      .detailContent {
        padding: 0 20px;
        background-color: #fff;
        flex-direction: column;
        .purpose {
          font-size: 16px;
          color: #707070;
          padding: 20px 0;
          border-bottom: 1px solid #707070;
        }
        .title {
          font-size: 14px;
          color: #a0a0a0;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .subTitle {
          font-size: 22px;
          color: $mainColor;
        }
        .content {
          font-size: 14px;
          color: #707070;
          margin: 20px 0;
        }
      }
    }
  }
}
@media screen and(max-width:1200px) {
  @import "./homeMax1200.module.scss";
}
