@import "@sass/global.scss";

.main {
  background: url("../../../images/news/newsBg.jpg") no-repeat;
  width: 100%;
  min-height: 100vh;
}
.center {
  width: 1278px;
  margin: 5% auto;
}
.article {
  position: relative;
  margin: 0 auto;
  .articleTitle {
    font-size: 35px;
    color: $mainColor;
    padding-bottom: 20px;
  }
  .articleTime {
    font-size: 14px;
    color: #969593;
    line-height: 2;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .articleDetail {
    font-size: 15px;
    color: #969593;
    img {
      width: 100%;
    }
  }
}
.switchWrap {
  width: 100%;
  background-color: #ececec;
  padding: 10px 20px;
  margin-top: 8%;
  border-radius: 5px;
  .arrowWrap {
    width: 50px;
    height: 50px;
    overflow: hidden;
    .arrow {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 576px) {
  .center {
    width: 100%;
    margin: 5% auto;
  }
  .article {
    position: relative;
    width: auto;
    margin: 0 auto;
    padding: 30px 15px;

    .articleTitle {
      font-size: 20px;
      color: $mainColor;
      padding-bottom: 15px;
      border-bottom: 1px solid #ddd;
    }

    .articleTime {
      font-size: 14px;
      color: #969593;
      line-height: 2;
      margin: 5px 0 20px;
    }

    .articleDetail {
      font-size: 15px;
      color: #969593;
    }

    img {
      width: 100%;
    }
  }
  .switchWrap {
    width: auto;
    background-color: #ececec;
    padding: 5px 20px;
    margin: 3% 10px !important;
    .arrowWrap {
      width: 30px;
      height: 30px;
      overflow: hidden;
      .arrow {
        width: 100%;
        height: 100%;
      }
    }
  }
}
