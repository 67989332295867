@import "@sass/global.scss";
.main {
  background: url("../../../images/contact/contactBg.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
}
.center {
  width: 1200px;
  margin: 5% auto;
  margin-top: 0%;
  background: #fff;
  padding: 50px 30px;
}
.headerBanner {
  width: 100%;
}
.mainTitle {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: $mainColor;
}

.contactDescription {
  display: flex;
  flex-direction: column;
  .descTitle {
    font-size: 30px;
    color: #646464;
    font-weight: 600;
  }
  .descSubTitle {
    margin-top: 16px;
    font-size: 18px;
    color: #646464;
    font-weight: 600;
  }
  .description {
    margin-top: 5px;
    font-size: 14px;
    color: #646464;
    margin-bottom: 40px;
  }
}
.required{
  &::before{
    content: '*';
    color: red;
  }
}
.form {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  border-top: 1px solid #bdbdbd;
  padding-top: 40px !important;
  // :global .ant-form-item {
  //   margin-bottom: 0 !important;
  // }
  .moduleItem {
    width: 60%;
    background: #ebeef0;
    padding: 20px;
    margin: 20px 0;
  }
  .formItem {
    .title {
      font-size: 16px;
      color: #646464;
      margin-bottom: 10px;
    }
  }
  .item {
    border: 1px solid #d8d6d6;
    border-radius: 5px;
    margin-bottom: 20px !important;
    background-color: #fff;
    input {
      border: none;
      font-size: 16px;
      outline: none;
    }
    :global .ant-input {
      background-color: transparent !important;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .select{
    :global .ant-select-selection--single{
      height: 40px;
    }
  }
  .message {
    width: 100%;
    margin-bottom: 20px;
    :global .ant-input {
      background-color: transparent !important;
      font-size: 16px !important;
      background-color: #fff !important;
      outline: none;
      &:focus {
        border: 1px solid #d8d6d6;
        box-shadow: none;
      }
    }
  }
  .buttonWrap {
    width: 100%;
    margin: 50px 0;
    .submit {
      background-color: #b51f1f;
      color: #fff;
      font-size: 16px;
      padding: 5px 40px;
      height: auto;
    }
  }
}

@media screen and (max-width: 576px) {
  .center {
    width: 100%;
    margin: 3% auto;
    padding: 30px 20px;
  }
  .mainTitle {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: $mainColor;
  }
  .contactList {
    margin: 5% auto;
    justify-content: center;
    flex-wrap: wrap;
    .contactItem {
      width: 94%;
      background-color: $footerBg;
      padding: 30px;
      margin-bottom: 10px;
      height: auto;
      .title {
        font-size: 20px;
        color: #fff;
        margin-bottom: 10px;
      }
      .duty,
      .phone,
      .email {
        font-size: 14px;
        color: #fff;
        margin-top: 10px;
      }
    }
  }
  .form {
    justify-content: center;
    .moduleItem {
      width: 100%;
      background: #ebeef0;
      padding: 20px;
      margin: 20px 0;
    }
    .item {
      width: 94%;
    }
    .message {
      width: 94%;
    }
    .buttonWrap {
      margin: 20px 0;
    }
  }
}
