@charset "utf-8";
// $mainBlue: #00009e;
$mainBlue: #0b93a1;
//浅蓝色
$wathet: #5656de;
$lightBlue: #00bbc9;
$yellow: #ff9d00;
$gray: #46585e;
$white: #fff;
$red: #fe0000;
$mainColor: #2c3c7c;
$footerBg: #313946;
