@import "@sass/global.scss";
.loading {
  display: none;
}
.banner {
  padding-top: 60%;
  .playerModal {
    .playerTitleFa {
      width: 300px;
      margin-top: 66px;
    }
    .playerTitle {
      font-size: 20px;
    }

    .playerSubTitle {
      margin-top: 20px;
      font-size: 14px;
      color: #fff;
    }
  }
}
// 关于我们
.about {
  .aboutWrap {
    max-width: 100%;
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    div {
      flex-direction: column;
      align-items: center;
    }
    .left {
      width: 100%;
      justify-content: center;
      .imgWrap {
        width: 66%;
        justify-content: flex-end;
        img {
          max-width: 100%;
          height: 100%;
        }
      }
      .logoAndTitle {
        position: relative;
        top: 0;
        left: 0;
        .logoTitleWrap {
          .title {
            margin-top: 0;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 600;
            color: $mainColor;
            // margin-top: 20px;
          }
        }
      }
    }
    .right {
      margin-left: 0;
      margin-top: 20px;
      width: 92%;
      .rightContent {
        justify-content: center;
        .title {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .content {
          margin-bottom: 10px;
        }
      }
    }
  }
}
// 我们的产品
.ourProducts {
  height: 316px;
  .ourProductsHeader {
    max-width: 100%;
    margin: 30px auto;
    .topContent {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 18px;
        font-weight: 600;
        color: $mainColor;
      }
      .content {
        display: block;
        text-align: center;
        font-size: 14px;
        color: $mainColor;
        margin-top: 10px;
      }
      .categoryList {
        margin-top: 10px;
        .item {
          font-weight: 600;
          margin: 0 5px;
          text-align: center;
          word-break: word-break;
        }
      }
      .categoryListDe {
        .item {
          margin: 0;
        }
      }
    }

    .content {
      display: none;
    }
  }
}
.productsContainer {
  // overflow: hidden;
  position: absolute;
  left: 0;
  top: 165px;
  height: 300px;
}
.productsContainerDe {
  top: 185px;
}
.productsListWrap {
  height: 100%;
  position: relative;
  width: 92%;
  max-width: 100%;
  margin: 0 auto;
  .mobileImgList {
    width: 100%;
    height: 100%;
    .item {
      width: 100%;
      height: 280px;
      .img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    :global {
      .slick-prev {
        left: 10px !important ;
      }
      .slick-next {
        right: 10px !important ;
      }
      .slick-prev,
      .slick-next {
        display: block;
        z-index: 2;
      }
      .slick-prev:before,
      .slick-next:before {
        color: #545454;
      }
    }
  }
}

// 项目管理
.projectManage {
  width: 100vw;
  background-color: #fff;
  padding-top: 170px;
  .projectContent {
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
    width: 92%;
    .headerWrap {
      flex-direction: column;
      .mainTitle {
        font-size: 17px;
      }
    }
    .projectList {
      flex-wrap: wrap;
      align-items: center;
      margin: 0;
      margin-top: 30px;
      .item {
        width: 50%;
        margin-bottom: 15px;
        .line {
          display: none;
        }
        .topWrap {
          align-items: center;
          justify-content: center;
          .imgWrap {
            width: 80px;
            height: 80px;
            img {
              width: 100%;
              border-radius: 50%;
              box-shadow: 0 0 5px 5px #e8e8e8;
            }
            .circle {
              width: 80px;
              height: 80px;
            }
          }
        }
        .bottomWrap {
          flex-direction: column;
          align-items: center;
          margin-top: 15px;
          min-height: 200px;
          padding: 0 10px;
          .title {
            font-size: 15px;
            color: #575757;
            margin-bottom: 20px;
          }
          .content {
            font-size: 14px;
            color: #707070;
            line-height: 1.5;
            width: 100%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            /* autoprefixer: ignore next */
            -webkit-box-orient: vertical;
          }
        }
        &:nth-child(1) {
          .line {
            &:nth-child(1) {
              opacity: 1;
            }
          }
        }
        &:nth-child(4) {
          .line {
            &:nth-child(2) {
              opacity: 1 !important;
            }
          }
        }
      }
    }
  }
}
// 公司分布
.companyLocation {
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #2b3c7a, #2c4191);
  padding: 20px 0;
  .locationContent {
    display: none;
  }
  .mobileLocationWrap {
    flex-direction: column;
    align-items: center;
    .imgWrap {
      width: 60%;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .imgWrapMap {
      width: 92%;
      margin: 20px 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .introduceWrap {
      width: 80%;
      .introduceContent {
        width: 100%;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
// 团队
.teamWrap {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
  padding: 0;
  padding-bottom: 20px;

  .mainTitle {
    margin: 20px 10px;
    font-size: 18px;
  }
  .teamContent {
    width: 92%;
    margin: 0 auto;
    flex-direction: column;
    .left {
      flex-direction: column;
      align-items: center;
      :global {
        .slick-prev {
          left: 15px !important;
          z-index: 6;
        }
        .slick-next {
          right: 30px !important;
          z-index: 6;
        }
      }
      .leftContent {
        width: 100%;
        .imgWrap {
          height: 340px !important;
          max-width: 100%;
          width: 100%;
          transition: all 2s ease-in-out;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .line {
            width: 100%;
            opacity: 1;
            animation: all 2s linear;
          }
        }
      }
    }
    .right {
      margin-left: 0px;
      .imgWrap {
        display: none;
      }
      .detailContent {
        .subTitle {
          font-size: 16px;
        }
      }
    }
  }
}
