@import "@sass/global.scss";
.recruitHeader {
  background-color: #fff;
  width: 100%;
  .headerContent {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .itemWrap {
      padding: 10px 20px;
      justify-content: center;
      border-bottom: 2px solid $red;
      &:nth-child(2) {
        border-bottom: none;
      }
      .title {
        font-size: 20px;
        color: $mainColor;
        font-weight: 600;
      }
    }
  }
}

// 每个模块的主标题
.mainTitle {
  margin: 20px 0;
  font-size: 20px;
  color: $mainColor;
  font-weight: 600;
  width: 100%;
}
// 招聘流程
.procedureWrap {
  position: relative;
  width: 1200px;
  margin: 80px auto;
  min-height: 25vh;
}
.procedureList {
  width: 100%;
  margin: 50px 0;
  .procedureListWrap {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .item {
      flex-direction: column;
      align-items: center;
      .imgWrap {
        justify-content: center;
        width: 116px;
        .img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .name {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 600px;
        color: #130f0f;
      }
    }
  }
}
// 宣讲行程
.tripWrap {
  min-height: 30vh;
  background: linear-gradient(to right, #2b3c7a, #2c4191);
  width: 100%;
  .tripContent {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    .tripContainer {
      display: flex;
      .left {
        width: 50%;
        .leftWrap {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            font-size: 20px;
            color: #fff;
            margin-bottom: 30px;
          }
          .content {
            font-size: 14px;
            color: #fff;
            line-height: 1.5;
            width: 70%;
          }
        }
      }
      .right {
        flex: 1;
        .imgWrap {
          flex-direction: column;
          img {
            max-width: 530px;
            max-height: 450px;
            width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
// 福利
.welfareWrap {
  min-height: 40vh;
  padding: 50px 0;
  background-color: #fff;
  .welfareContent {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column;
    .welfareContainer {
      width: 100%;
      flex-direction: column;
    }
  }
}
.welfareList {
  margin: 30px 0;
  align-items: center;
  flex-wrap: wrap;
  .welfareListWrap {
    width: 100%;
    justify-content: space-around;
    .item {
      width: 24%;
      border-radius: 10px;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 0 20px 5px #f1f1f1;
      .imgWrap {
        width: 100%;
        img {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .name {
        margin: 15px 0;
        font-size: 18px;
        color: $mainColor;
      }
      .content {
        font-size: 14px;
        color: #9f9f9f;
        text-align: center;
        margin-bottom: 15px;
        line-height: 2;

        p {
          padding: 0 15px;
          text-align: center;
          word-break: break-word;
        }
      }
    }
  }
}
// 薪酬
.wageWrap {
  // min-height: 60vh;
  background: url("../../../images/recruit/wageBg.jpg") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 431px;
  .wageContent {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 20px;
      color: #fff;
    }
    .content {
      margin-top: 30px;
      font-size: 16px;
      color: rgba($color: #fff, $alpha: 0.8);
      line-height: 1.2;
    }
  }
}

//招聘列表
.recruitWrap {
  min-height: 60vh;
  position: relative;
  width: 1200px;
  margin: 50px auto;
  .recruitContent {
    width: 100%;
    flex-direction: column;
  }
}
.recruitList {
  width: 100%;
  .recruitListWrap {
    width: 100%;
    flex-direction: column;
    .item {
      margin-bottom: 20px;
      padding: 30px 25px;
      background-color: #fff;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 0px 2px 2px #f5f5f5;
      flex-direction: column;

      .titleWrap {
        justify-content: space-between;
        .title {
          font-size: 20px;
          color: $mainColor;
          margin-bottom: 25px;
        }
      }
      .basicInfoOne,
      .basicInfoTwo {
        align-items: center;
        .txt {
          margin: 0 10px;
          font-size: 14px;
          color: #8f8f8f;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .basicInfoOne {
        margin-bottom: 10px;
      }
      .detailInfo {
        display: none;
        margin-top: 30px;
        flex-direction: column;
        transition: all 1s linear;
        &.active {
          display: flex;
        }
        .title {
          font-size: 16px;
          color: #8f8f8f;
          margin-bottom: 20px;
        }
        .demand {
          font-size: 14px;
          color: #8f8f8f;
        }
        .bottomWrap {
          margin-top: 30px;
          font-size: 18px;
          color: #d44440;
          font-weight: 600;
          align-items: center;
          .bottomItem {
            &:first-child {
              margin-right: 20px;
              color: #d44440;
            }
          }
        }
      }
    }
  }
}
.page {
  text-align: center;
  margin-top: 20px;
}
@media screen and (max-width: 576px) {
  .recruitHeader {
    background-color: #fff;
    width: 100%;
    .headerContent {
      max-width: 100%;
      .itemWrap {
        padding: 10px 0;
        width: 50%;
        justify-content: center;
        border-bottom: 2px solid $red;
        &:nth-child(2) {
          border-bottom: none;
        }
        .title {
          font-size: 18px;
        }
      }
    }
  }
  // 每个模块的主标题
  .mainTitle {
    font-size: 18px;
    margin-left: 20px;
  }
  // 招聘流程
  .procedureWrap {
    width: 100%;
    margin: 0 auto;
    min-height: 30vh;
  }
  .procedureList {
    width: 92%;
    margin: 0 auto;
    .procedureListWrap {
      width: 100%;
      flex-wrap: wrap;
      .item {
        flex-direction: column;
        align-items: center;
        width: 33%;
        margin-bottom: 30px;
        .imgWrap {
          justify-content: center;
          width: 80px;
        }
        .name {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
  // 宣讲行程
  .tripWrap {
    min-height: 40vh;
    .tripContent {
      max-width: 100%;
      .tripContainer {
        flex-direction: column;
        align-items: center;
        width: 92%;
        margin: 0 auto;
        .left {
          width: 100%;
          .leftWrap {
            margin: 20px 0;
            .title {
              font-size: 18px;
              color: #fff;
              margin-bottom: 20px;
            }
            .content {
              width: 100%;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  // 福利
  .welfareWrap {
    padding: 10px 0;
    .welfareContent {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .welfareList {
    margin: 0;
    align-items: center;
    flex-wrap: wrap;
    .welfareListWrap {
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
      .item {
        width: 46%;
        margin-bottom: 15px;
        .name {
          font-size: 16px;
          text-align: center;
        }
        .content {
          line-height: 1.2;
          margin-bottom: 5px;
        }
      }
    }
  }
  // 薪酬
  .wageWrap {
    height: auto;
    .wageContent {
      width: 92%;
      padding: 20px 0;
      align-items: center;
      .title {
        text-align: center;
        font-size: 18px;
      }
      .content {
        font-size: 14px;
        text-align: center;
      }
    }
  }
  //招聘列表
  .recruitWrap {
    min-height: 60vh;
    position: relative;
    width: 92%;
    margin: 10px auto;
    .recruitContent {
      width: 100%;
      flex-direction: column;
    }
  }
  .recruitList {
    .recruitListWrap {
      width: 100%;
      flex-direction: column;
      .item {
        margin-bottom: 20px;
        padding: 30px 25px;
        background-color: #fff;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 0px 2px 2px #f5f5f5;
        flex-direction: column;
        .titleWrap {
          justify-content: space-between;
          .title {
            font-size: 16px;
            color: $mainColor;
            margin-bottom: 25px;
          }
        }
        .basicInfoOne,
        .basicInfoTwo {
          align-items: center;
          .txt {
            margin: 0 5px;
            font-size: 13px;
            color: #8f8f8f;
            &:first-child {
              margin-left: 0;
            }
          }
        }
        .detailInfo {
          .email {
            margin-top: 20px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .page {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px !important;
  }
}
