@import "@sass/global.scss";

:global .ant-pagination-item-active {
  background-color: $mainColor !important;
  border-color: $mainColor !important;
}
:global .ant-pagination-item-active a {
  color: #fff !important;
}
.main {
  position: relative;
  background: url("../../../images/news/newsBg.jpg") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.headerBanner {
  width: 100%;
}
.center {
  width: 1200px;
  margin: 5% auto;
  margin-top: 3%;
}
.headerWrap {
  width: 100%;
  border-bottom: 3px solid #e7e7e5;
  .headerTitle {
    font-size: 24px;
    color: $mainColor;
    border-bottom: 3px solid $mainColor;
  }
}
.left {
  padding-right: 5%;
}
.newsList {
  .newsItem {
    position: relative;
    padding: 30px 0;
    border-bottom: 2px solid #e5e5e5;
    overflow: hidden;
    transition: all 0.2s linear;
    &:hover {
      // box-shadow: 0px 0px 10px #bebfbf;
      .newsImg {
        transform: scale(1.1);
      }
      .newsDetails {
        .newsTitle {
          color: $mainColor;
        }
        .newsDetail {
          background-color: #b51f1f;
        transition: all 0.2s linear;
          color: #fff;
        }
      }
    }
    .newsImgFa {
      width: 270px;
      height: 166px;
      overflow: hidden;
      .newsImg {
        transition: all 0.2s linear;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .newsDetails {
      flex: 1;
      margin-right: 35px;
      .newsTitle {
        font-size: 22px;
        color: #434343;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      }
      .newsDescription {
        align-items: flex-start;
        margin: 15px 0;
        font-size: 14px;
        color: #969593;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
      }
      .newsDetail {
        display: inline-block;
        margin-top: 10px;
        color: #838383;
        font-size: 14px;
        padding: 5px 30px;
        border: 1px solid #b51f1f;
      }
    }
  }
}
.pagination {
  margin: 20px 0 !important;
}
.dataList {
  .dataItem {
    margin: 30px 0;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 10px;
    .dataLeft {
      .filesWrap {
        width: 45px;
        height: 38px;
        overflow: hidden;
        .files {
          width: 100%;
          height: 100%;
        }
      }
      .dataName {
        margin-left: 20px;
        font-size: 16px;
        color: #969593;
      }
    }

    .downloadWrap {
      width: 20px;
      height: 23px;
      overflow: hidden;
      .download {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.switchWrap {
  width: 100%;
  padding: 10px 36%;
  margin-top: 5%;
  border-radius: 5px;
  .arrowWrap {
    width: 50px;
    height: 50px;
    overflow: hidden;
    .arrow {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .center {
    width: 100%;
  }
  .headerWrap {
    border-bottom: 3px solid #e7e7e5;
    .headerTitle {
      font-size: 18px;
      color: $mainColor;
      border-bottom: 3px solid $mainColor;
    }
  }
  .left {
    padding: 0 10px;
    margin: 3% auto;
  }
  .right {
    margin-top: 4%;
    padding: 0 10px;
  }
  .newsList {
    width: auto;
    margin: 0 auto 30px;
    padding: 0;
    .newsItem {
      position: relative;
      margin-bottom: 0;
      overflow: hidden;
      transition: all 0.2s linear;
      display: block;
      padding: 15px 0;
      .newsImgFa {
        margin-top: 10px;
      }
      .icon {
        display: none;
      }
      .newsDetails {
        width: 100%;
        margin-right: 0 !important;
        justify-content: center !important;
        .newsTitle {
          font-size: 18px;
        }
        .newsDescription {
          align-items: flex-start;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          margin: 10px 0 !important;
        }
        .newsDetail {
          margin-top: 0px;
        }
      }
    }
  }
  .dataList {
    .dataItem {
      margin: 15px 0;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 10px;
      .dataLeft {
        .filesWrap {
          width: 45px;
          height: 38px;
          overflow: hidden;
          .files {
            width: 100%;
            height: 100%;
          }
        }
        .dataName {
          margin-left: 20px;
          font-size: 16px;
          color: #969593;
        }
      }

      .downloadWrap {
        width: 20px;
        height: 23px;
        overflow: hidden;
        .download {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .switchWrap {
    width: auto;
    margin-top: 1%;
    .arrowWrap {
      width: 40px;
      height: 40px;
      overflow: hidden;
      .arrow {
        width: 100%;
        height: 100%;
      }
    }
  }
}
