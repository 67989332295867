@import "@sass/global.scss";
.hidden {
  display: none;
}
.main {
  position: relative;
  // background: $footerBg !important;
  background: $mainColor !important;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 !important;
}
.footer {
  width: 1200px;
  margin: 20px auto;
  margin-bottom: 0;
  color: #fff;
}
.footerLeft {
  margin-right: 60px;
  .logoWrap {
    width: 202px;
    .img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .contact {
    margin: 20px 0;
    position: relative;
    .contactItem {
      .contactIcon {
        width: 35px;
        height: 35px;
        color: #fff;
        font-size: 22px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
    .wechatImgWrap {
      position: absolute;
      top: -69px;
      left: 16px;
      .wechatImg {
        width: 66px;
        height: 66px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
.footerRight {
  flex-direction: column;
  .title {
    font-size: 16px;
    color: #fff;
  }
  .productList {
    flex-wrap: wrap;
    margin-top: 10px;
    .item {
      // width: 25%;
      text-align: left;
      margin-right: 20px;
      // color: rgba(255, 255, 255, 0.5);
      color: #fff;
      margin-bottom: 10px;
      font-size: 14px;
      &:hover {
        color: $red;
      }
    }
  }
}
.footerMiddle {
  flex-direction: column;
  .title {
    font-size: 16px;
    color: #fff;
  }
  .phone,
  .email {
    font-size: 14px;
    color: #fafafa;
    margin-top: 10px;
    .contentTxt {
      font-size: 14px;
      color: #fafafa;
      display: inline-block;
      .line {
        width: 0%;
        height: 2px;
        // background-color: #4472c4;
        background-color: $red;
        transition: all 0.5s linear;
      }
      &:hover {
        .line {
          width: 100%;
        }
      }
    }
  }
}
.footerBottom {
  width: 100%;
  border-top: 1px solid #202631;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  .recordNumber {
    text-align: right;
    font-size: 14px;
    color: #fafafa;
  }
  .recordInformation {
    text-align: right;
    font-size: 14px;
    color: #fafafa;
    .recordNum {
      font-size: 14px;
      color: #fafafa;
    }
  }
}

@media screen and (max-width: 1200px) {
  .main {
    position: relative;
    background: $footerBg !important;
    .footer {
      width: auto;
      color: #fff;
      padding-bottom: 0;
      border-bottom: none;
      padding: 0 10px;
    }
    .footerLeft {
      display: flex;
      margin-right: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .logoWrap {
        height: 37px;
      }
      .contact {
        margin: 0;
      }
    }
    .footerMiddle {
      margin: 10px 0;
      flex-direction: row;
      flex-wrap: wrap;
      .title {
        width: 100%;
        font-size: 16px;
        color: #fff;
      }
      .phone,
      .email {
        font-size: 14px;
        color: #fafafa;
        margin-top: 5px;
        width: 100%;
      }
      .email {
        // margin-left: 10px;
      }
    }
    .footerRight {
      margin-bottom: 10px;
      .title {
        font-size: 16px;
        color: #fff;
      }
      .productList {
        flex-wrap: wrap;
        margin-top: 5px;
        .item {
          width: 25%;
          text-align: left;
          margin-bottom: 5px;
          color: #fff;
          word-break: break-word;
          font-size: 14px;
          &:hover {
            color: $red;
          }
        }
      }
    }
    .footerBottom {
      width: 100%;
      border-top: 1px solid #202631;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      flex-direction: column;
      .recordNumber {
        text-align: right;
        font-size: 14px;
        color: #fafafa;
      }
      .recordInformation {
        text-align: right;
        font-size: 14px;
        color: #fafafa;
        .recordNum {
          font-size: 14px;
          color: #fafafa;
        }
      }
    }
  }
}
