@import "@sass/global.scss";
$primary: #3650ad;
$dark-primary: #303f9f;
$light-primary: #2b3c7a;
$text: #ffffff;
$primary-text: #212121;
$secondary-text: #757575;
$accent: #2b3c7a;
.hidden {
  display: none;
}
html {
  font-size: 16px;
}
.flex {
  display: flex;
}
.aboutWrap {
  width: 100%;
}
.headerBanner {
  width: 100%;
}
.introduceOne {
  background-color: #f6f7fb;
}
.introduceTwo {
  background-color: #fff;
}
// 介绍内容1
.introduceItem {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;

  padding: 50px 0;
  align-items: center;
  .left {
    width: 50%;
    .leftWrap {
      // width: 66.6%;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 50px;
      .title {
        font-size: 28px;
        color: $mainColor;
      }
      .content {
        margin-top: 30px;
        font-size: 16px;
        color: #98989a;
        line-height: 2.5;
      }
    }
  }
  .right {
    flex: 1;
    padding-left: 50px;
    justify-content: center;
    .imgWrap {
      height: 366px;

      img {
        height: 100%;
      }
    }
  }
}
// 介绍内容2
.introduceItemTwo {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 50px 0;
  align-items: center;
  .left {
    width: 50%;
    padding-right: 50px;
    justify-content: flex-end;
    .imgWrap {
      width: 586px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  .right {
    flex: 1;
    justify-content: flex-start;
    padding-left: 50px;
    .rightWrap {
      // width: 66.6%;
      flex-direction: column;
      align-items: flex-end;
      .title {
        font-size: 28px;
        color: $mainColor;
      }
      .content {
        margin-top: 30px;
        font-size: 16px;
        color: #919191;
        line-height: 3;
      }
    }
  }
}

// 发展历史
.history {
  min-height: 520px;
  width: 100%;
  margin: 0 auto;
  background-color: #f6f7fb;
  padding: 100px 0;
  display: flex;
  justify-content: flex-end;
}
.historyWrap {
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
  .topContent {
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .arrowWrap {
      width: 100px;
      justify-content: space-between;
      .imgWrap {
        width: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
  .title {
    font-size: 24px;
    color: $mainColor;
  }
}
.historyListWrap {
  margin-top: 30px;
  position: relative;
  width: 100%;
  height: 380px;
  overflow: hidden;
}
.historyList {
  position: absolute;
  top: 0;
  .item {
    width: 266px;
    margin-right: 20px;
    flex-direction: column;
    .line {
      width: 100%;
      height: 6px;
      background-color: $mainColor;
      margin-top: 20px;
    }
    .contentWrap {
      flex-direction: column;
      margin-top: 50px;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: $mainColor;
        margin-bottom: 10px;
      }
      .content {
        // border: 1px solid red;
        // height: 100px;
        width: 92%;
        font-size: 16px;
        color: #98989a;
        margin-bottom: 5px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 4;
        // /* autoprefixer: ignore next */
        // -webkit-box-orient: vertical;
      }
      .imgWrap {
        // margin: 0 auto;
        margin-top: 20px;
        width: 200px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}

// 具體内容介紹
.detailIntro {
  width: 100%;
  min-height: 40vh;
  position: relative;
  background-color: #fff;
}
.detailIntroWrap {
  width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  .title {
    font-size: 24px;
    color: $mainColor;
    margin: 60px 0 20px 0;
  }
}
.detailList {
  width: 100%;
  flex-direction: column;
  align-items: center;
  .item {
    padding: 50px 0;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .leftWrap {
      flex-direction: column;
      width: 50%;
      .imgWrap {
        width: 586px;
        height: 310px;
        transition: all 0.2s linear;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .rightWrap {
      flex: 1;
      position: relative;
      flex-direction: column;
      .contentList {
        flex-direction: column;
        flex: 1;
        padding-left: 50px;
        .title {
          font-size: 24px;
          color: $mainColor;
          margin: 30px 0;
        }
        .contentWrap {
          width: 66%;
          flex-direction: column;
          line-clamp: 2;
          .content {
            font-size: 16px;
            color: #98989a;
          }
        }
      }
      .numberWrap {
        position: absolute;
        right: 0;
        top: -80px;
        .numImg {
          width: 93px;
          height: 199px;
          img {
            width: 100%;
          }
        }
      }
    }
    &:nth-child(2) {
      flex-direction: row-reverse;
      .leftWrap {
        align-items: flex-end;
      }
      .rightWrap {
        .contentList {
          padding-left: 0;
        }
        .numberWrap {
          right: 0;
        }
      }
    }
  }
}
@media screen and(max-width:1200px) {
  // 介绍内容1
  .introduceItem {
    max-width: 100%;
    margin: 0 20px;
    width: auto;
    padding: 20px 0;
    flex-direction: column-reverse;
    .left {
      width: 100%;
      .leftWrap {
        padding-right: 0;
        .title {
          font-size: 18px;
          color: $mainColor;
        }
        .content {
          margin-top: 10px;
          font-size: 14px;
          line-height: 2;
        }
      }
    }
    .right {
      padding-left: 0;
      .imgWrap {
        height: 266px;
        img {
          width: 100%;
          object-fit: fill;
          height: 100%;
        }
      }
    }
  }
  // 介绍内容2
  .introduceItemTwo {
    max-width: 100%;
    margin: 0 20px;
    width: auto;
    padding: 20px 0;
    flex-direction: column;
    .left {
      width: 100%;
      padding-right: 0px;
      justify-content: center;
      .imgWrap {
        width: 586px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
    .right {
      justify-content: flex-start;
      padding-left: 0px;
      .rightWrap {
        align-items: flex-start;
        .title {
          margin-top: 20px;
          font-size: 18px;
        }
        .content {
          margin-top: 10px;
          font-size: 14px;
          line-height: 2;
        }
      }
    }
  }
  // 发展历史
  .history {
    min-height: 420px;
    width: 100%;
    margin: 0 auto;
    background-color: #f6f7fb;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .historyWrap {
    display: none;
  }
  .mobileHistoryWrap {
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    .title {
      font-size: 18px;
      color: $mainColor;
      font-weight: 600;
      margin-left: 20px;
    }
    .mobileHistory {
      margin-top: 20px;
      width: 100%;
      flex-direction: column;
      .item {
        width: 100%;
        margin-right: 20px;
        flex-direction: column;
        align-items: center;
        min-height: auto;
        padding: 0 20px;
        background-color: #fff;
        box-shadow: 0 3px 5px #f1f1f1;
        .line {
          margin: 20px;
          background-color: $mainColor;
          width: 100%;
          height: 4px;
        }
        .contentWrap {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0;
          .title {
            font-size: 20px;
            margin-left: 0;
            margin-bottom: 0;
          }
          .content {
            // border: 1px solid red;
            // height: 100px;
            width: 92%;
            font-size: 14px;
            color: #98989a;
            margin: 10px 0 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            /* autoprefixer: ignore next */
            -webkit-box-orient: vertical;
          }
          .imgWrap {
            width: 200px;
            margin-bottom: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
  }
  // 具體内容介紹
  .detailIntro {
    min-height: 40vh;
  }
  .detailIntroWrap {
    margin: 0 20px;
    width: auto;

    .title {
      font-size: 18px;
      margin: 20px 0 20px 0;
    }
  }
  .detailList {
    width: 100%;
    flex-direction: column;
    align-items: center;
    .item {
      padding: 0;
      padding-bottom: 10px;
      align-items: center;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      .leftWrap {
        flex-direction: column;
        width: 100%;
        .imgWrap {
          width: 100%;
          height: auto !important;
        }
      }
      .rightWrap {
        flex: 1;
        position: relative;
        flex-direction: column;
        .contentList {
          flex-direction: column;
          flex: 1;
          padding-left: 0;
          .title {
            font-size: 16px;
            color: $mainColor;
            margin: 10px 0;
          }
          .contentWrap {
            width: 100%;
            flex-direction: column;
            line-clamp: 2;
            .content {
              font-size: 14px;
              color: #98989a;
            }
          }
        }
        .numberWrap {
          display: none;
          position: absolute;
          right: 0;
          top: 0px;
          .numImg {
            width: 73px;
            height: 139px;
            img {
              width: 100%;
            }
          }
        }
      }
      &:nth-child(2) {
        flex-direction: column;

        .rightWrap {
          width: 100%;
          .contentList {
            padding-left: 0;
          }
        }
      }
    }
  }
}
