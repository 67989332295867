@import "@sass/global.scss";
@import "~antd/dist/antd.css";
:local {
  .headerHeight {
    height: 90px;
    z-index: 100;
    // :global {
    //   .ant-progress-success-bg,
    //   .ant-progress-bg {
    //     transition: all 0.4s cubic-bezier(1, 1, 1, 1) 1s !important;
    //   }
    //   .ant-progress-status-active .ant-progress-bg:before {
    //     background: #ff0000 !important;
    //     animation: ant-progress-active 6s cubic-bezier(1, 1, 1, 1) infinite !important;
    //     opacity: 1 !important;
    //   }
    //   .antd
    //   @keyframes ant-progress-active {
    //     0% {
    //       width: 0;
    //       background: #ff0000 !important;
    //       color: #ff0000 !important;
    //       opacity: 1 !important;
    //     }
    //     100% {
    //       width: 100%;
    //       background: #ff0000 !important;
    //       opacity: 1 !important;
    //       color: #ff0000 !important;
    //     }
    //   }
    // }
  }
  .progress {
    position: fixed !important;
    top: 79px;
    z-index: 999;
  }
  
  @media screen and (max-width: 940px) {
    .navList {
        display: none;
    }
  }
  @media screen and (min-width: 768px) {
    .navListMob {
      display: none;
    } 
  }
  @keyframes move {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .progressContanier1 {
    width: 100%;
    position: absolute;
    left: 0;
    top: 91px;
    z-index: 101;
    .line {
      width: 100%;
    }
  }
  .progressContanier {
    width: 100%;
    position: fixed;
    left: 0;
    top: 89px;
    z-index: 101;
    background: #fff;
    .close{
      // width: 100px;
      position: absolute;
      right: 10px;
      top: 0;
      z-index: 101;
      cursor: pointer;
    }
  }
  .progressWrap {
    width: fit-content;
    height: 20px;
    position: relative;
    // text-align: center;
    animation: load 24s linear infinite;
    overflow:hidden;
    z-index: 101;
    color: black;
    // .fillProgress {
    //   width: 100%;
    //   height: 100%;
    //   background: #fe0000; /* Old browsers */
    //   background-size: 60px 30px;
    //   text-align: center;
    //   color: #fff;
    //   // animation: load 2s linear infinite;
    // }
  }
  @keyframes load {
    0% { left: 0; }
    100% { left: 100%;}

  }
  .header {
    position: fixed;
    width: 100%;
    height: 90px !important;
    background: $mainColor !important;
    z-index: 100;
    .main {
      position: relative;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      // logo样式
      .logoWrap {
        // display: flex !important;
        .logo {
          width: 106px;
          margin-top: 10px;
          .logoImg {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }

      // 电脑版菜单

      .navList {
        position: relative;
        height: 90px;
        line-height: 90px;
        justify-content: flex-end;
        position: relative;
        .navItem {
          height: 85px;
          display: block;
          margin: 0 15px;
          padding: 0 10px;
          position: relative;
          transition: all 0.2s linear;
          .navItemTitleActive {
            font-size: 16px;
            color: $red;
            position: relative;
          }
          .navItemTitle {
            font-size: 16px;
            color: #fff;
            position: relative;
          }
        }
        .navItem:hover {
          // border-bottom: 2px solid $red;
          .navItemTitle {
            color: $red;
          }
        }
      }
    }
    // 图标icon
    .icons {
      height: 90px;
      line-height: 90px;
      text-align: right;
      position: relative;
      .icon {
        padding: 20px;
        color: red;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  .selectLanguage {
    position: absolute;
    // width: 150px;
    // background: #fff;
    padding: 10px 0;
    top: 48px;
    right: 0px;
    // margin-left: 56px;
    display: flex;
    .selectLanguageItem {
      // height: 40px;
      line-height: 1;
      cursor: pointer;
      justify-content: center;
      margin-left: 10px;
      .languageIcon {
        width: 25px;
        height: 25px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .navItem{
      padding: 0 !important;
      // margin: 10px !important;
    }
    .headerHeight {
      height: 60px;
      z-index: 100;
    }
    .progressContanier {
      top: 57px;
    }
    .progressWrap {
      height: 20px;
      .fillProgress {
        animation: load 4s linear infinite;
      }
    }
    .progress {
      display: none !important;
      position: fixed !important;
      top: 55px !important;
    }
    .header {
      height: 60px !important;
      line-height: 60px;
      padding: 0 20px !important;
      .main {
        width: 100%;
        .home,
        .nav,
        .nav.ul {
          height: 60px;
          line-height: 60px;
        }
        
          .navList{
            line-height: 59px;
          }
        .home {
          cursor: pointer;
          .menuIcon {
            font-size: 18px;
            color: #fff;
          }
        }
        .logoWrap {
          height: 60px;
          line-height: 60px;
          display: flex !important;
          justify-content: center;
          align-items: center;
          .logo {
            width: 44px;
            margin-top: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }

        .icons {
          height: 60px;
          line-height: 60px;
          text-align: right;
          .icon {
            padding: 20px 0 20px 15px;
            color: #fff;
            margin-left: 3px;
          }
        }
      }
      // 手机版导航列表
      .mobileHeader {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        .content {
          position: relative;
          padding: 30px;
          .navListMob {
            line-height: 1.5;
            .navItem {
              margin-bottom: 25px;
              width: 100%;
              .navTitle {
                font-size: 20px;
                color: $mainColor;
              }
              .navIcon {
                font-size: 14px;
                color: $mainColor;
                margin-left: 10px;
              }
            }
          }
        }
      }
      // 手机版语言切换
      .selectLanguage {
        position: fixed;
        width: 100%;
        background: #fff;
        padding: 10px 0;
        top: 60px;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .selectLanguageItem {
          height: 40px;
          line-height: 1;
          cursor: pointer;
          justify-content: center;
          margin-left: 10px;
          .languageIcon {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
