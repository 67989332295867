@font-face {
  font-family: "heavy";
  src: url("../font/SOURCEHANSANSSC-HEAVY.OTF");
}

@font-face {
  font-family: "normal";
  src: url("../font/SOURCEHANSANSSC-NORMAL.OTF");
}
body {
  font-weight: lighter;
}
body,
html {
  margin: 0;
  padding: 0;
}
// #root {
//   overflow: auto;
//   height: 100%;
//   border: 1px solid red;
// }
.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}
.flexDirectionColumn {
  flex-direction: column;
}
.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceArrow {
  justify-content: space-around;
}

.justifyContentCenter {
  justify-content: center;
}
.justifyContentEnd {
  justify-content: flex-end;
}
.alignItemsCenter {
  align-items: center;
}

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsEnd {
  align-items: flex-end;
}

.fontFamily {
  font-family: "微软雅黑";
}

.hidden {
  display: none;
}
@media screen and (max-width: 576px) {
  body {
    font-weight: normal;
  }
}
