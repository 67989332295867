@import "@sass/global.scss";
.main {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.recruitHeader {
  background-color: #fff;
  width: 100%;
  .headerContent {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .itemWrap {
      padding: 10px 20px;
      justify-content: center;
      border-bottom: 2px solid $red;
      &:nth-child(1) {
        border-bottom: none;
      }
      .title {
        font-size: 20px;
        color: $mainColor;
        font-weight: 600;
      }
    }
  }
}
.recruitList {
  // min-height: 20vh;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  .item {
    margin-bottom: 20px;
    padding: 30px 25px;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 0px 2px 2px #f5f5f5;
    flex-direction: column;

    .titleWrap {
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
        color: $mainColor;
        // margin-bottom: 25px;
      }
    }
    .basicInfoOne,
    .basicInfoTwo {
      align-items: center;
      flex-wrap: wrap;
      .txt {
        margin: 0 10px;
        font-size: 14px;
        color: #8f8f8f;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .basicInfoOne {
      margin-bottom: 10px;
    }
    .detailInfo {
      display: none;
      margin-top: 30px;
      flex-direction: column;
      transition: all 1s linear;
      &.active {
        display: flex;
      }
      .title {
        font-size: 16px;
        color: #8f8f8f;
        margin-bottom: 20px;
      }
      .demand {
        font-size: 14px;
        color: #8f8f8f;
      }

      .bottomWrap {
        margin-top: 30px;
        font-size: 18px;
        color: #d44440;
        font-weight: 600;
        align-items: center;
        .bottomItem {
          &:first-child {
            margin-right: 20px;
            color: #d44440;
          }
        }
      }
    }
  }
  .page {
    text-align: center;
    margin-top: 20px;
  }
}
@media screen and (max-width: 576px) {
  .recruitHeader {
    background-color: #fff;
    width: 100%;
    .headerContent {
      max-width: 100%;
      .itemWrap {
        padding: 10px 0;
        width: 50%;
        justify-content: center;
        border-bottom: 2px solid $red;
        &:nth-child(1) {
          border-bottom: none;
        }
        .title {
          font-size: 18px;
        }
      }
    }
  }
  // 每个模块的主标题
  .mainTitle {
    font-size: 18px;
    margin-left: 20px;
  }
  //招聘列表
  .recruitList {
    min-height: 80vh;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 10px auto;
    .item {
      margin-bottom: 10px;
      padding: 10px 10px;
      .titleWrap {
        .title {
          font-size: 18px;
        }
      }
    }
    .page {
      text-align: center;
      margin-top: 20px;
    }
  }
}
