@import "@sass/global.scss";
$redColor: #b51f1f;

.flex {
  display: flex;
}
.headerBanner {
  width: 100%;
}
.main {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
// 服务导航
.serviceNavWrap {
  width: 100%;
  align-items: center;
  padding: 100px 0;
  .navContent {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
  }
}
.squareToEllipse {
  width: 1100px;
  height: 400px;
  border-radius: 60%;
  // background-color: #e1e4f3;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $mainColor;
  position: relative;
  .logoWrap {
    width: 191px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
.pcNav {
  width: 100%;
  height: 100%;
}
.squareItemHidden {
  opacity: 0;
  animation: all 6s 1 ease-in-out;
}
.squareItemCenter {
  background: linear-gradient(to top, #880809, #c61a1c) !important;
  &::before {
    background: linear-gradient(to top, #c61a1c, #940e0f) !important;
  }
}
.squareItem {
  position: absolute;
  background: linear-gradient(to top, #161e3d, #2b3c7a);
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  text-align: center;
  animation: all 6s 1;
  animation-fill-mode: forwards;
  opacity: 1;
  .name {
    position: relative;
    z-index: 2;
    font-size: 20px;
    color: rgba($color: #fff, $alpha: 0.8);
    text-shadow: 0px 1px 6px #fff;
  }
  &{
    transition: all .6s;

  }
  &::before {
    position: absolute;
    z-index: 1;
    left: calc((100% - 85%) / 2);
    top: calc((100% - 85%) / 2);
    content: "";
    width: 85%;
    height: 85%;
    border-radius: 50%;
    background: linear-gradient(to top, #26366d, #17234b);
  }
  &:nth-child(2) {
    top: -60px;
    left: 310px;

    &:hover {
      top: -90px;
    }
  }
  &:nth-child(3) {
    top: 130px;
    left: -60px;

    &:hover {
      top: 100px;
    }
  }
  &:nth-child(4) {
    bottom: -60px;
    left: 405px;
    &:hover {
      bottom: -30px;
    }
  }
  &:nth-child(5) {
    bottom: 25px;
    right: 10px;
    &:hover {
      bottom: 55px;
    }
  }
  &:nth-child(6) {
    top: -55px;
    right: 210px;
    &:hover {
      top: -85px;
    }
  }
  &:nth-child(7) {
    top: -21px;
    right: 203px;
    &:hover {
      top: -45px;
    }
  }
}

// 详细内容介绍
.detailContentWrap {
  width: 100%;
  background: url("../../../images/service/louBg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 569px;
  align-items: center;
  position: relative;
  .detailWrap {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
  }
}
.maskWrap {
  // display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.6);
  .maskContent {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1200px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .maskDetailContent {
      width: 60%;
      justify-content: space-around;
      .detailContent {
        flex-direction: column;
        .title {
          font-size: 24px;
          color: #fff;
          margin: 20px 0;
        }
        .partsList {
          flex-direction: column;
          .parts {
            font-size: 18px;
            color: #fff;
          }
        }
      }
    }
  }
}
.serviceList {
  width: 100%;
  :global {
    .slick-dots {
      text-align: left !important;
    }

    .ant-carousel .slick-dots li button {
      opacity: 0.8 !important;
    }

    .slick-dots li {
      width: 30px !important;
    }
    .slick-dots li button {
      background: rgba($color: #fff, $alpha: 0.6) !important;
      width: 30px !important;
      height: 3px !important;
      padding: 0px !important;
    }
    .slick-dots li.slick-active button {
      background: rgba($color: #fff, $alpha: 1) !important;
      width: 30px !important;
      height: 3px !important;
      padding: 0px !important;
    }
    .slick-dots li button:before {
      font-family: "slick";
      font-size: 6px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "" !important;
      text-align: center;
      opacity: 0.25;
      -webkit-font-smoothing: antialiased;
    }
  }
  .item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 353px;
    .left {
      flex: 1;
      height: 100%;
      .leftWrap {
        width: 90%;
        flex-direction: column;
        height: 100%;
        .title {
          font-size: 24px;
          color: #fff;
          font-weight: 600;
        }
        .description {
          margin-top: 30px;
          font-size: 16px;
          color: #fff;
        }
        .content,
        .materials {
          margin-top: 30px;
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .right {
      width: 50%;
      .imgContentWrap {
        position: relative;
        width: 600px;
        height: 100%;
        .imgWrap {
          width: 600px;
          height: 100%;
          :global {
            .slick-prev:before,
            .slick-next:before {
              display: block !important;
            }
            .slick-prev,
            .slick-next {
              z-index: 2;
              width: 45px !important;
              height: 45px !important;
              background: #00000090;
              border-radius: 50% !important;
              line-height: 7px;
              &:focus,
              &:hover {
                background: #00000090;
              }
            }
            .slick-prev {
              left: 10px;
              &:before {
                display: block;
                text-align: center;
                font-size: 30px;
                content: "<";
                line-height: 1;
                color: #fff;
              }
            }
            .slick-next {
              right: 10px;
              &:before {
                display: block;
                text-align: center;
                font-size: 30px;
                color: #fff;
                content: ">";
                line-height: 1;
              }
            }
          }

          .imgItem {
            width: 400px;
            height: 400px;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
        .left,
        .right {
          position: absolute;
          width: 80px;
          top: 50%;
          margin-top: -25px;
          height: 80px;
          background: rgba(255, 255, 255, 0.4);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .icon {
            font-size: 20px;
            color: #fff;
          }
        }
        .left {
          left: 0;
        }
        .right {
          right: 0;
        }
      }

      .mediaWrap {
        width: 600px;
        height: 100%;
        .player {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
// 项目咨询
.projectConsultion {
  position: relative;

  padding: 100px 0;

  .projectContent {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .left {
      flex: 1;
      background: #fff;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .leftWrap {
        width: 80%;
        height: 345px;
        flex-direction: column;
        .title {
          font-size: 24px;
          color: $mainColor;
          font-weight: 600;
          margin: 20px 0;
        }
        .introduce {
          font-size: 16px;
          color: #afafaf;
          margin: 5px 0;
          display: flex;
          flex: 1;
          .square {
            display: block;
            width: 15px;
            height: 15px;
            margin-top: 5px;
            border: 1px solid #333;
            box-shadow: 1px 1px 0 0 #333;
            margin-right: 20px;
            white-space: nowrap;
          }
          .pageTxt {
            flex: 1;
            margin-bottom: 0;
          }
        }
        .email {
          font-size: 18px;
          color: #44444d;
        }
        .descritpion {
          margin-top: 30px;
          color: #afafaf;
          font-size: 16px;
        }
        .contact {
          margin-top: 30px;
          border-radius: 40px;
          width: 200px;
          background-color: #b51f1f;
          color: #fff;
          text-align: center;
          font-size: 18px;
          padding: 10px 10px;
        }
      }
    }
    .right {
      width: 600px;
      .rightContent {
        width: 100%;
        height: 445px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
@media screen and(max-width:578px) {
  .serviceNavWrap {
    width: 100%;
    align-items: center;
    padding: 20px 0;
    .navContent {
      width: 100%;
      margin: 0 auto;
      max-width: 100%;
      align-items: center;
      text-align: center;
      justify-content: center;
      position: relative;
    }
  }
  // 导航菜单
  .mobileNav {
    width: auto;
    margin: 0 20px;
    flex-direction: column;
    .logoWrap {
      margin-left: 10px;
      width: 56%;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .navList {
      margin-top: 20px;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      .itemWrap {
        width: 33%;
        flex-direction: column;
        align-items: center;
      }
      .squareItemHidden {
        opacity: 0;
        animation: all 6s 1 ease-in-out;
      }
      .squareMobileItemCenter {
        background: linear-gradient(to top, #880809, #c61a1c) !important;
        &::before {
          background: linear-gradient(to top, #c61a1c, #940e0f) !important;
        }
      }
      .squareMobileItem {
        margin-bottom: 10px;
        position: relative;
        background: linear-gradient(to top, #161e3d, #2b3c7a);
        width: 90px;
        height: 90px;
        line-height: 90px;
        border-radius: 50%;
        text-align: center;
        animation: all 6s 1;
        animation-fill-mode: forwards;
        opacity: 1;
        .name {
          position: relative;
          z-index: 2;
          font-size: 14px;
          color: #fff;
          // text-shadow: 0px 1px 6px #fff;
        }
        &::before {
          position: absolute;
          z-index: 1;
          left: calc((100% - 85%) / 2);
          top: calc((100% - 85%) / 2);
          content: "";
          width: 85%;
          height: 85%;
          border-radius: 50%;
          background: linear-gradient(to top, #26366d, #17234b);
        }
      }
    }
  }
  :global {
    .ant-carousel .slick-dots li {
      margin: 0 6px !important;
    }
  }
  //切换列表
  .mobileServiceList {
    width: 96%;
    margin: 0 auto;
    .mobileItem {
      flex-direction: column;
      align-items: center;
      // border: 1px solid red;

      .left {
        flex: 1;
        height: 100%;
        .leftWrap {
          width: 100%;
          flex-direction: column;
          align-items: center;
          height: 100%;
          .title {
            font-size: 18px;
            color: #fff;
            font-weight: 600;
          }
          .description {
            margin-top: 15px;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
          .content,
          .materials {
            margin-top: 15px;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
        }
      }
      .right {
        margin-top: 20px;
        width: 100%;
        .imgContentWrap {
          position: relative;
          width: 100%;
          height: 100%;
          .imgWrap {
            width: 100%;
            height: 236px;
            :global {
              .slick-prev:before,
              .slick-next:before {
                display: block !important;
              }

              .slick-prev,
              .slick-next {
                z-index: 2;
                width: 45px !important;
                height: 45px !important;
                background: #00000090;
                border-radius: 50% !important;
                line-height: 7px;
                &:focus,
                &:hover {
                  background: #00000090;
                }
              }
              .slick-prev {
                left: 10px;
                &:before {
                  display: block;
                  text-align: center;
                  font-size: 30px;
                  content: "<";
                  line-height: 1;
                  color: #fff;
                }
              }
              .slick-next {
                right: 10px;
                &:before {
                  display: block;
                  text-align: center;
                  font-size: 30px;
                  color: #fff;
                  content: ">";
                  line-height: 1;
                }
              }
            }

            .imgItem {
              width: 100%;
              height: 236px;
              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }
          }
          .left,
          .right {
            position: absolute;
            width: 80px;
            top: 50%;
            margin-top: -25px;
            height: 80px;
            background: rgba(255, 255, 255, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .icon {
              font-size: 20px;
              color: #fff;
            }
          }
          .left {
            left: 0;
          }
          .right {
            right: 0;
          }
        }

        .mediaWrap {
          width: 100%;
          height: 100%;
          .player {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }
  }
  .maskWrap {
    .maskContent {
      max-width: 100%;
      .maskDetailContent {
        width: 100%;
      }
    }
  }
  // 项目咨询
  .projectConsultion {
    position: relative;
    padding: 20px 0;
    .projectContent {
      max-width: 100%;
      width: 96%;
      flex-direction: column;
      .left {
        flex: 1;
        .leftWrap {
          width: 80%;
          height: auto;
          .title {
            font-size: 18px;
          }
          .introduce {
            font-size: 14px;
          }
          .email {
            font-size: 12px;
          }
          .descritpion {
            margin-top: 20px;
            font-size: 14px;
          }
          .contact {
            margin: 30px 0;
            border-radius: 20px;
            font-size: 14px;
            padding: 5px 10px;
          }
        }
      }
      .right {
        width: 100%;
        .rightContent {
          height: 265px;
        }
      }
    }
  }
}
