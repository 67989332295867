@import "@sass/global.scss";
.hidden {
  display: none;
}
.center {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.contentWrap {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("./../../images/common/mapStart1.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mainContent {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  height: 70vh;
  flex-direction: column;
  justify-content: space-between;

  font-size: 32px;
  .logoWrap {
    // display: hidden;
    // opacity: 0;
    width: 380px;
    margin-left: 3rem;
    margin-top: 10rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
.btnList {
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 2em 0;
  margin-bottom: 1em;
  // border: 1px solid red;
  .item {
    flex-direction: column;
    align-items: center;
    width: 33%;

    .title {
      font-size: 18px;
      // color: rgba($color: #fff, $alpha: 0.8);
      color: rgba($color: $mainColor, $alpha: 1);
      margin-bottom: 40px;
    }

    .languageBtn {
      font-size: 20px;
      color: rgba($color: #fff, $alpha: 1);
      // color: rgba($color: #2b3c7a, $alpha: 0.8);
      border-radius: 40px;
      padding: 10px 50px;
      background: rgba($color: $mainColor, $alpha: 1);
      // border: 1px solid rgba($color: #fff, $alpha: 0.8);
      border: 1px solid rgba($color: $mainColor, $alpha: 1);
    }
    .titleTwo {
      font-size: 18px;
      // color: rgba($color: #fff, $alpha: 0.8);
      color: rgba($color: #fff, $alpha: 0.8);
      margin-bottom: 40px;
    }
    .languageBtnTwo {
      font-size: 20px;
      // color: $mainColor;
      color: rgba($color: #fff, $alpha: 0.8);
      border-radius: 40px;
      // background: rgba($color: #fff, $alpha: 0.8);
      background: rgba($color: #2b3c7a, $alpha: 0.6);
      padding: 10px 50px;
      // border: 1px solid rgba($color: #fff, $alpha: 0.8);
      border: 1px solid rgba($color: #2b3c7a, $alpha: 0.6);
    }
    &:hover {
      .title {
        font-size: 18px;
        // color: rgba($color: #fff, $alpha: 0.8);
        color: rgba($color: $red, $alpha: 1);
        margin-bottom: 40px;
      }
      .languageBtn {
        font-size: 20px;
        // color: rgba($color: #fff, $alpha: 0.8);
        color: rgba($color: #fff, $alpha: 1);
        border-radius: 40px;
        padding: 10px 50px;
        background: rgba($color: $red, $alpha: 1);
        border: 1px solid rgba($color: $red, $alpha: 1);
      }
    }
  }
}
// 分布点
.dotWrap {
  position: absolute;
  right: 47.5%;
  top: 32%;
  // top: 34%;
  vertical-align: middle;
  .name {
    font-size: 16px;
    // color: rgba($color: #fff, $alpha: 0.8);
    color: rgba($color: $mainColor, $alpha: 0.8);
  }
}
// .dotWrapActive {
//   position: absolute;
//   right: 47.5%;
//   top: 32%;
//   // top: 34%;
//   vertical-align: middle;
//   .name {
//     font-size: 16px;
//     // color: rgba($color: #fff, $alpha: 0.8);
//     color: rgba($color: $mainColor, $alpha: 0.8);
//   }
// }
.dotWrapTwo {
  position: absolute;
  // right: 25%;
  // top: 39.5%;
  right: 25%;
  top: 40.5%;
  vertical-align: middle;
  .name {
    font-size: 16px;
    // color: rgba($color: #fff, $alpha: 0.8);
    color: rgba($color: $mainColor, $alpha: 0.8);
  }
}
.dotWrapThree {
  position: absolute;
  // right: 27.5%;
  // top: 54%;
  right: 28%;
  top: 58%;
  vertical-align: middle;
  .name {
    font-size: 16px;
    // color: rgba($color: #fff, $alpha: 0.8);
    color: rgba($color: $mainColor, $alpha: 0.8);
  }
}
.dotCircle {
  background: #fe0000;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 5px;
  .dot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 1px 0px $red;
    animation: sploosh 3s infinite;
  }
  .dot1 {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 1px 0px $red;
    animation: sploosh 3s infinite;
    animation-delay: 1s;
  }
  .dot2 {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    // box-shadow: 0 0 1px 0px #fff;
    box-shadow: 0 0 1px 0px $red;
    animation: sploosh 3s infinite;
    animation-delay: 2s;
  }
}
.dotCircleTwo {
  background: $red;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 15px;
  margin-top: 5px;
  .dot {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 0 1px 0px $red;
    animation: sploosh 3s infinite;
  }
  .dot1 {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 1px 0px $red;
    animation: sploosh 3s infinite;
    animation-delay: 1s;
  }
  .dot2 {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 1px 0px $red;
    animation: sploosh 3s infinite;
    animation-delay: 2s;
  }
}

@keyframes sploosh {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  50% {
    transform: scale(3.5);
  }
  75% {
    transform: scale(5);
  }
  100% {
    transform: scale(6.5);
  }
}
@media screen and (max-width: 1440px) {
  .mainContent {
    font-size: 20px !important;
  }
}
