@import "@sass/global.scss";

:global .slick-prev:before {
  color: #000 !important;
}

:global .slick-next:before {
  color: #000 !important;
}

:global .slick-dots li.slick-active button:before {
  color: transparent !important;
}

:global .slick-dots li button:before {
  color: transparent !important;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "" !important;
  text-align: center;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
}

.flex {
  display: flex;
}

.headerBanner {
  width: 100%;
}

.main {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

// 车的简介
.productIntroduce {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 100px 0 0 0;

  .informationsliste {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    justify-content: space-between;

    .infor_l {
      width: 77%;
      background: ghostwhite;

      .l_allin {
        display: flex;
        justify-content: space-between;
      }

      .infor_text {
        width: 40%;
        color: #2c3c7c;
        font-size: 16px;
        padding: 44px;

        .text_name {
          margin: 60px 0 30px 0;
        }

        ul {
          margin: 0;
          list-style: none;
          padding: 0;
          margin-top: 30px;

          li {
            font-size: 14px;
            margin-top: 10px;

            &::before {
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              background: #2c3c7c;
              border-radius: 50%;
              margin-right: 7px;
            }
          }
        }
      }

      .infor_img {
        width: 80%;
        margin-top: 24px;
        position: relative;

        img {
          width: 75%;
        }

        .swiperLIst {
          width: 85%;
          max-height: 360px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          img {
            max-height: 300px;
          }
        }
      }

      h3 {
        font-size: 24px;
        color: #2c3c7c;
        font-weight: 600;
      }
    }

    .infor_r {
      width: 20%;
      font-size: 16px;

      div {
        width: 100%;
        height: 70px;
        text-align: center;
        line-height: 70px;
        background: ghostwhite;
        color: #2c3c7c;
        cursor: pointer;
      }

      .inforCol {
        background: #2c3c7c;
        color: #fff;
      }
    }
  }

  .carWrap {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 600px;

    .logoWrap {
      font-weight: 600;
      position: absolute;
      top: -30px;
      left: 0;
      font-size: 24px;
      color: $mainColor;
    }
  }
}

.carContent {
  width: 100%;
  position: relative;

  .carImg {
    width: 100%;
    justify-content: center;
    padding-top: 100px;
    width: 1200px;

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }

  .contentWrap {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    .item {
      position: absolute;
      transition: all 0.5s linear;

      .name {
        background-color: $mainColor;
        color: #fff;
        font-size: 14px;
        padding: 5px 10px;
        position: absolute;
        text-align: center;
        width: 128px;
      }

      &:nth-child(1) {
        top: -22px;
        left: 39%;

        &:hover {
          top: -36px;
        }

        .name {
          top: -24%;
          left: -440%;
        }
      }

      &:nth-child(2) {
        top: -24px;
        left: 48.5%;

        &:hover {
          top: -38px;
        }

        .name {
          top: -18%;
          left: 26%;
        }
      }

      &:nth-child(3) {
        top: -50px;
        right: 26%;

        &:hover {
          top: -64px;
        }

        .name {
          top: -18%;
          left: 26%;
        }
      }

      &:nth-child(4) {
        bottom: 278px;
        left: 15.7%;

        &:hover {
          bottom: 262px;
        }

        .name {
          bottom: -58%;
          left: -29.3%;
        }
      }

      &:nth-child(5) {
        bottom: 121px;
        left: 33%;

        &:hover {
          bottom: 107px;
        }

        .name {
          bottom: -30%;
          left: -448%;
        }
      }

      &:nth-child(6) {
        bottom: 127px;
        left: 42%;

        &:hover {
          bottom: 113px;
        }

        .name {
          bottom: -16%;
          left: 23.7%;
        }
      }
    }

    .nameWrap {
      position: absolute;
      width: 100%;
      height: 100%;

      .name {
        background-color: $mainColor;
        color: #fff;
        font-size: 14px;
        padding: 5px 10px;
        position: absolute;
        text-align: center;
        width: 100px;

        &:nth-child(1) {
          top: -61px;
          left: 35.5%;
        }

        &:nth-child(2) {
          top: -60px;
          left: 51.5%;
        }

        &:nth-child(3) {
          top: -87px;
          right: 22%;
        }

        &:nth-child(4) {
          bottom: 39%;
          left: 9.7%;
        }

        &:nth-child(5) {
          bottom: 14%;
          left: 24%;
        }

        &:nth-child(6) {
          bottom: 14%;
          left: 39%;
        }
      }
    }
  }
}

// 产品系列
.productListWrap {
  width: 100%;
  background-color: #fff;

  .productsContainer {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
    height: 488px;
    overflow: hidden;

    .productLeft {
      width: 75%;
      height: 100%;
      background-color: $mainColor;

      // background-color: #fff;
      .detailWrap {
        align-items: center;

        .left {
          width: 400px;
          flex-direction: column;
          margin: 0 40px;

          .allTitle {
            font-size: 24px;
            color: #fff;
            margin-bottom: 50px;
          }

          .title {
            font-size: 18px;
            color: #fff;
          }
        }

        .right {
          flex: 1;

          .imgWrap {
            width: 356px;

            img {
              height: 100%;
              width: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }

    .productRight {
      flex: 1;
      height: 100%;

      .infor_img {
        width: 80%;
        margin-top: 24px;
        position: relative;

        .swiperList {
          width: 85%;
          max-width: 360px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          img {
            max-height: 300px;
          }
        }
      }
    }
  }

  // 3.31修改
  .modifyProductsContainer {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
    // height: 488px;
    overflow: hidden;
    background: $mainColor;

    .modifyProductLeft {
      width: 30%;

      .left {
        width: 100%;
        flex-direction: column;
        margin: 0 40px;

        .allTitle {
          font-size: 24px;
          color: #fff;
          // color: #2b3c7a;
          margin-bottom: 50px;
        }

        .title {
          font-size: 18px;
          color: #fff;
          // color: #2b3c7a;
        }
      }
    }

    .modifyProductRight {
      flex: 1;
      border: 1px solid rgba(#fff, 0.8);
      overflow: hidden;

      .productsList {
        :global {

          .slick-prev:before,
          .slick-next:before {
            display: none;
          }
        }

        width: 100%;

        // border: 1px solid red;
        // flex-wrap: wrap;
        .item {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 200px;
          position: relative;
          border: 1px solid rgba(#fff, 0.8);

          .imgWrap {
            width: 168px;

            .img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }

          .maskWrap {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            opacity: 0;
            transition: all 1s linear;
            background-color: $mainColor;

            .maskContent {
              // padding: 30px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;

              .name {
                font-size: 20px;
                color: #fff;
                padding-bottom: 10px;
                border-bottom: 2px solid $red;
                margin-bottom: 20px;
              }

              .content {
                font-size: 18px;
                color: #fff;
              }

              .detailWrap {
                margin-top: 20px;

                .detail {
                  border: 1px solid #fff;
                  font-size: 14px;
                  color: #fff;
                  padding: 5px 10px;
                }
              }
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        // .itemActive {
        //   border-bottom: 2px solid #b51f1f;
        //   display: flex !important;
        //   flex-direction: column;
        //   align-items: center;
        //   justify-content: center;
        //   background-color: #ecedf2;
        //   // width: 100%;
        //   width: 33.33%;
        //   height: 120px;
        //   .imgWrap {
        //     width: 80px;
        //     height: 80px;
        //     .img {
        //       width: 100%;
        //     }
        //   }
        // }
      }

    }

    .infor_r {
      width: 20%;
      font-size: 16px;

      div {
        width: 100%;
        height: 70px;
        text-align: center;
        line-height: 70px;
        background: #2c3c7c;
        color: ghostwhite;
        cursor: pointer;
      }

      .inforCol {
        background: #fff;
        color: #2c3c7c;
      }
    }
  }
}

// .productsList {
//   :global {
//     .slick-prev:before,
//     .slick-next:before {
//       display: none;
//     }
//   }
//   width: 100%;

//   // flex-direction: column;
//   // align-items: center;
//   .item {
//     display: flex !important;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     background-color: #ecedf2;
//     opacity: 0.6;
//     width: 100%;
//     height: 120px;
//     .imgWrap {
//       width: 80px;
//       height: 80px;
//       .img {
//         width: 100%;
//       }
//     }
//   }
//   .itemActive {
//     border-bottom: 2px solid #b51f1f;
//     display: flex !important;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     background-color: #ecedf2;
//     width: 100%;
//     height: 120px;
//     .imgWrap {
//       width: 80px;
//       height: 80px;
//       .img {
//         width: 100%;
//       }
//     }
//   }
// }
// 其他产品
.otherProductsWrap {
  position: relative;
  background: #fff;
  padding: 100px 0;

  :global {

    .slick-prev,
    .slick-next {
      &::before {

        color: black;
      }
    }
  }

  .otherProducts {
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .title {
      font-size: 24px;
      color: $mainColor;
      font-weight: 600;
    }

    .otherList {
      width: 100%;
      margin-top: 50px;

      .otherListWrap {
        width: 100%;
        align-items: center;

        .item {
          position: relative;
          width: 33.3%;

          &:nth-child(2) {
            justify-content: center;
          }

          &:last-child {
            justify-content: flex-end;
          }

          .itemContent {
            position: relative;

            .imgWrap {
              width: 380px;
              height: 242px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }

              .name {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: rgba(#2b3c7a, 0.2);
                font-size: 18px;
                color: #fff;
                padding: 5px 0;
                padding-left: 30px;
                font-weight: 600;
              }
            }

            .maskWrap {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 380px;
              height: 100%;
              opacity: 0;
              transition: all 1s linear;
              background-color: $mainColor;

              .maskContent {
                // padding: 30px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;

                .name {
                  font-size: 20px;
                  color: #fff;
                  padding-bottom: 10px;
                  border-bottom: 2px solid $red;
                  margin-bottom: 20px;
                }

                .content {
                  font-size: 18px;
                  color: #fff;
                }

                .detailWrap {
                  margin-top: 20px;

                  .detail {
                    border: 1px solid #fff;
                    font-size: 14px;
                    color: #fff;
                    padding: 5px 10px;
                  }
                }
              }

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  // 车的简介
  .productIntroduce {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 50px 0;
    padding-bottom: 20px;

    .carWrap {
      width: 100%;
      height: auto;

      .logoWrap {
        position: absolute;
        top: -30px;
        left: 20px;
        font-size: 18px;
      }
    }
  }

  .carContent {
    width: auto;
    margin: 0 20px;
    position: relative;

    .carImg {
      width: 100%;
      justify-content: center;
      padding-top: 10px;
      width: 100%;

      img {
        object-fit: fill;
        width: 100%;
        height: 100%;
      }
    }

    .contentWrap {
      display: none;
    }

    .mobileNavContent {
      margin-top: 10px;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 30%;
        background-color: $mainColor;
        color: #fff;
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 20px;
        padding: 3px 0;
      }
    }
  }

  // 产品系列
  .productListWrap {
    background-color: $mainColor;

    .productsContainer {
      display: none;
    }

    .mobileContainer {
      width: 100%;
      margin: 0 auto;
      max-width: 100%;
      align-items: center;
      height: auto;
      flex-direction: column;

      .infor_r {
        width: 100%;
        font-size: 14px;

        div {
          width: 100%;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: #2c3c7c;
          color: ghostwhite;
          cursor: pointer;
        }

        .inforCol {
          background: #fff;
          color: #2c3c7c;
        }
      }

      .productLeft {
        margin: 0 20px;
        flex-direction: column;
        margin-top: 20px;

        .allTitle {
          margin: 10px 0;
          font-size: 18px;
          margin-bottom: 10px;
          color: #fff;
          text-align: center;
        }

        .title {
          font-size: 14px;
          color: #fff;
        }
      }

      .productRight {
        margin: 20px 0;
        width: 100%;
        flex-direction: column;

        .right {
          margin-top: 20px;
          flex-direction: column;
          align-items: center;

          .imgWrap {
            width: 60%;

            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }

    // 3.31修改
    .modifyProductsContainer {
      display: none;
    }
  }

  .mobileProductsList {
    :global {

      .slick-prev:before,
      .slick-next:before {
        display: none;
      }
    }

    width: 100%;

    .item {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #ecedf2;
      opacity: 0.6;
      width: 100%;
      height: 120px;

      .imgWrap {
        width: 60px;
        height: 60px;

        .img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }

    .itemActive {
      border-bottom: 2px solid #b51f1f;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #ecedf2;
      width: 100%;
      height: 120px;

      .imgWrap {
        width: 80px;
        height: 80px;

        .img {
          width: 100%;
        }
      }
    }
  }

  // 其他产品
  .otherProductsWrap {
    padding: 20px 0;
    min-height: 40vh;

    .otherProducts {
      max-width: 100%;
      margin: 0 20px;
      width: auto;

      .title {
        font-size: 18px;
      }

      .otherList {
        margin-top: 20px;

        .otherListWrap {
          width: 100%;
          align-items: center;
          flex-direction: column;
          align-items: center;

          .item {
            position: relative;
            width: 100%;
            margin-bottom: 20px;

            .itemContent {
              position: relative;
              width: 100%;

              .imgWrap {
                width: 100%;
                // height: auto !important;
                position: relative;

                .name {
                  font-size: 16px;
                  padding-left: 20px;
                }
              }

              .maskWrap {
                width: 100%;

                .maskContent {
                  .name {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}