@import "@sass/global.scss";
.bannerWrap {
  width: 100%;
  justify-content: center;
  height: 301px;
  .bannerContent {
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
    .mainTitle {
      font-size: 32px;
      font-weight: 600;
      color: #fff;
    }
    .subTitle {
      margin-top: 10px;
      font-size: 14px;
      color: rgba($color: #fff, $alpha: 0.8);
    }
  }
}
@media screen and (max-width: 1200px) {
  .bannerWrap {
    height: 185px;
  }
  .bannerContent {
    max-width: 100%;
    padding: 0 20px;
    .mainTitle {
      font-size: 24px !important;
    }
  }
}
